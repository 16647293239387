import React from "react";
import Header from "src/components/Header";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="fb-root" />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "(function(d, s, id) {\nvar js, fjs = d.getElementsByTagName(s)[0];\nif (d.getElementById(id)) return;\njs = d.createElement(s); js.id = id;\njs.src = \"https://connect.facebook.net/de_DE/sdk.js#xfbml=1&appId=272376829538322&version=v2.0\";\nfjs.parentNode.insertBefore(js, fjs);\n}(document, 'script', 'facebook-jssdk'));"
          }}
        />
        <Header></Header>
        <div id="meganav">
          <div className="container">
            {" "}
            <a
              href="/index.html"
              title="bebe Zartpflege®"
              target="_self"
              className="navbar-brand"
            >
              <em>
                bebe Zartpflege<sup>®</sup>
              </em>
            </a>
            <div className="close-meganav" />
            <div className="col-xs-12 meganav-top">
              {" "}
              <a
                href="/index.html"
                target="_self"
                title="Start"
                className="navitem"
              >
                Start
              </a>{" "}
              <a
                href="/menu/kontakt/index.html"
                target="_self"
                title="Kontakt"
                className="navitem"
              >
                Kontakt
              </a>{" "}
              <a
                href="/menu/faq/index.html"
                target="_self"
                title="FAQ"
                className="navitem"
              >
                FAQ
              </a>{" "}
              <a
                href="/menu/impressum/index.html"
                target="_self"
                title="Impressum"
                className="navitem"
              >
                Impressum
              </a>{" "}
              <a
                href="/menu/rechtliche-hinweise/index.html"
                target="_self"
                title="Rechtliche Hinweise"
                className="navitem"
              >
                Rechtliche Hinweise
              </a>{" "}
              <a
                href="/menu/cookie-information/index.html"
                target="_self"
                title="Cookies"
                className="navitem"
              >
                Cookies
              </a>{" "}
              <a
                href="/menu/allg-gewinnspielbedingungen/index.html"
                target="_self"
                title="Allg. Gewinnspielbedingungen"
                className="navitem"
              >
                Allg. Gewinnspielbedingungen
              </a>{" "}
            </div>
            <div id="col-products" className="cat-links col-xs-12 col-sm-3">
              {" "}
              <a
                className="cat-thumb"
                href="/produkte/index.html"
                target="_self"
              >
                {" "}
                <img
                  src="/fileadmin/images/_layout/meganav.thumb.produkte.png"
                  alt="Produkte"
                />{" "}
              </a>
              <ul>
                <li>
                  <a
                    href="/produkte/index.html"
                    target="_self"
                    title="Produkte"
                  >
                    Produkte
                  </a>
                </li>
                <li>
                  <ul className="sub-navi">
                    <li>
                      <a
                        href="/produkte/index.html#ZartcremeinderDose"
                        target="_self"
                      >
                        Zartcreme
                      </a>
                    </li>
                    <li>
                      <a
                        href="/produkte/index.html#schaumbad-und-dusche"
                        target="_self"
                      >
                        Schaumbad und Dusche
                      </a>
                    </li>
                    <li>
                      <a
                        href="/produkte/index.html#Glanzshampoo"
                        target="_self"
                      >
                        Glanzshampoo
                      </a>
                    </li>
                    <li>
                      <a
                        href="/produkte/index.html#Pflegelotion"
                        target="_self"
                      >
                        Pflegelotion
                      </a>
                    </li>
                    <li>
                      <a
                        href="/produkte/index.html#MildeHandseife"
                        target="_self"
                      >
                        Milde Handseife
                      </a>
                    </li>
                    <li>
                      <a
                        href="/produkte/#shampoo"
                        target="_self"
                      >
                        Stärkendes Shampoo und Spülung
                      </a>
                    </li>
                    <li>
                    <a
                      href="/produkte/#Lippenpflegemit"
                      target="_self"
                    >  
                     Lippenpflege mit fruchtigem Kirschduft
                    </a>
                </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div
              id="col-unsere-maedels"
              className="cat-links col-xs-12 col-sm-3"
            >
              {" "}
              <a
                className="cat-thumb"
                href="/unsere-maedels/index.html"
                target="_self"
              >
                {" "}
                <img
                  src="/fileadmin/images/_layout/meganav.thumb.unsere-maedels.png"
                  alt="Unsere Mädels"
                />{" "}
              </a>
              <ul>
                <li>
                  <a
                    href="/unsere-maedels/index.html"
                    target="_self"
                    title="Unsere Mädels"
                  >
                    Unsere Mädels
                  </a>
                </li>
                <li>
                  <ul className="sub-navi">
                    <li>
                      <a
                        href="/unsere-maedels/index.html#BibiBlubberblase"
                        target="_self"
                      >
                        Bibi Blubberblase
                      </a>
                    </li>
                    <li>
                      <a
                        href="/unsere-maedels/index.html#MiaMorgentau"
                        target="_self"
                      >
                        Mia Morgentau
                      </a>
                    </li>
                    <li>
                      <a
                        href="/unsere-maedels/index.html#PaulaPerlensanft"
                        target="_self"
                      >
                        Paula Perlensanft
                      </a>
                    </li>
                    <li>
                      <a
                        href="/unsere-maedels/index.html#ViviWallemaehne"
                        target="_self"
                      >
                        Vivi Wallemähne
                      </a>
                    </li>
                    <li>
                      <a
                        href="/unsere-maedels/index.html#SophieSonnenschein"
                        target="_self"
                      >
                        Sophie Sonnenschein
                      </a>
                    </li>
                    <li>
                      <a
                        href="/unsere-maedels/index.html#Malbuch"
                        target="_self"
                      >
                        Mal- &amp; Rätselbuch
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div id="col-marken" className="cat-links col-xs-12 col-sm-3">
              {" "}
              <a
                className="cat-thumb"
                href="/markenhistorie/index.html"
                target="_self"
              >
                {" "}
                <img
                  src="/fileadmin/images/_layout/meganav.thumb.markenhistorie.png"
                  alt="Markenhistorie"
                />{" "}
              </a>
              <ul>
                <li>
                  <a
                    href="/markenhistorie/index.html"
                    target="_self"
                    title="Markenhistorie"
                  >
                    Markenhistorie
                  </a>
                </li>
                <li>
                  <ul className="sub-navi">
                    <li>
                      <a href="/markenhistorie/index.html#60er" target="_self">
                        60er
                      </a>
                    </li>
                    <li>
                      <a href="/markenhistorie/index.html#70er" target="_self">
                        70er
                      </a>
                    </li>
                    <li>
                      <a href="/markenhistorie/index.html#80er" target="_self">
                        80er
                      </a>
                    </li>
                    <li>
                      <a href="/markenhistorie/index.html#90er" target="_self">
                        90er
                      </a>
                    </li>
                    <li>
                      <a
                        href="/markenhistorie/index.html#2000er"
                        target="_self"
                      >
                        2000er
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div
              id="col-limited-editions"
              className="cat-links col-xs-12 col-sm-3"
            >
              {" "}
              <a
                className="cat-thumb"
                href="/limited-editions/index.html"
                target="_self"
              >
                {" "}
                <img
                  src="/fileadmin/images/_layout/meganav.thumb.limited_editions.png"
                  alt="Limited Editions"
                />{" "}
              </a>
              <ul>
                <li>
                  <a
                    href="/limited-editions/index.html"
                    target="_self"
                    title="Limited Editions"
                  >
                    Limited Editions
                  </a>
                </li>
                <li>
                  <ul className="sub-navi">
                    <li>
                      <a
                        href="/limited-editions/index.html#OnlineGame"
                        target="_self"
                      >
                        Online Game
                      </a>
                    </li>
                    <li>
                      <a
                        href="/limited-editions/index.html#LimitedEditions"
                        target="_self"
                      >
                        Limited Editions
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="col-xs-12 meganav-mobile-bottom">
              {" "}
              <a
                className="navitem"
                title="Start"
                target="_self"
                href="/index.html"
              >
                Start
              </a>{" "}
              <a
                className="navitem"
                title="Kontakt"
                target="_self"
                href="/menu/kontakt/index.html"
              >
                Kontakt
              </a>{" "}
              Newsletter{" "}
            </div>
          </div>
        </div>
        <div id="stage" className="markenhistorie animated-stage">
          <div className="container">
            <div className="row text-center">
              <div className="stage-text col-md-10 col-md-offset-1">
                <h1>Markenhistorie</h1>
                <p>
                  {" "}
                  Seit mehr als 50 Jahren pflegt{" "}
                  <strong>
                    bebe<sup>®</sup>
                  </strong>{" "}
                  junge Haut zart und geschmeidig:
                  <br />
                  Die Geschichte von{" "}
                  <strong>
                    bebe Zartpflege<sup>®</sup>
                  </strong>
                  .{" "}
                </p>
              </div>
              <div className="stage-image col-xs-12 desktop-view">
                {" "}
                <img
                  src="/fileadmin/images/markenhistorie/markenhistorie.stage.bg.png"
                  width="940"
                  height="310"
                  alt
                />
                <div className="animated-items">
                  {" "}
                  <a
                    href="/markenhistorie/index.html#year-1992"
                    className="textlink-scroll"
                  >
                    <img
                      className="animated-item item-1"
                      src="/fileadmin/images/markenhistorie/markenhistorie.stage.1992.produkte.png"
                      alt
                    />
                  </a>{" "}
                  <a
                    href="/markenhistorie/index.html#year-1976"
                    className="textlink-scroll"
                  >
                    <img
                      className="animated-item item-2"
                      src="/fileadmin/images/markenhistorie/markenhistorie.stage.1976.png"
                      alt
                    />
                  </a>{" "}
                  <a
                    href="/markenhistorie/index.html#year-1976"
                    className="textlink-scroll"
                  >
                    <img
                      className="animated-item item-3"
                      src="/fileadmin/images/markenhistorie/markenhistorie.stage.1976.produkt.png"
                      alt
                    />
                  </a>{" "}
                  <a
                    href="/markenhistorie/index.html#year-1961"
                    className="textlink-scroll"
                  >
                    <img
                      className="animated-item item-4"
                      src="/fileadmin/images/markenhistorie/markenhistorie.stage.1961.png"
                      alt
                    />
                  </a>{" "}
                  <a
                    href="/markenhistorie/index.html#year-2019"
                    className="textlink-scroll"
                  >
                    <img
                      className="animated-item item-6"
                      src="/fileadmin/images/markenhistorie/markenhistorie.stage.2019.png"
                      alt
                    />
                  </a>{" "}
                  <a
                    href="/markenhistorie/index.html#year-2019"
                    className="textlink-scroll"
                  >
                    <img
                      className="animated-item item-5"
                      src="/fileadmin/images/markenhistorie/markenhistorie.stage.2019.produkt1.png"
                      alt
                    />
                  </a>{" "}
                  <a
                    href="/markenhistorie/index.html#year-1961"
                    className="textlink-scroll"
                  >
                    <img
                      className="animated-item item-7"
                      src="/fileadmin/images/markenhistorie/markenhistorie.stage.1961.produkt.png"
                      alt
                    />
                  </a>{" "}
                  <a
                    href="/markenhistorie/index.html#year-2019"
                    className="textlink-scroll"
                  >
                    <img
                      className="animated-item item-8"
                      src="/fileadmin/images/markenhistorie/markenhistorie.stage.2019.produkt2.png"
                      alt
                    />
                  </a>{" "}
                </div>
              </div>
              <div className="stage-image col-xs-12 mobile-view">
                {" "}
                <img
                  src="/fileadmin/images/markenhistorie/markenhistorie.stage.bg.mobile.png"
                  width="320"
                  height="196"
                  alt
                />{" "}
                <a
                  href="/markenhistorie/markenhistorie/#content"
                  className="textlink-scroll"
                />{" "}
              </div>
            </div>
            <div className="bubbles-container">
              <div className="bubble1" />
              <div className="bubble2" />
              <div className="bubble3" />
              <div className="bubble4" />
            </div>
          </div>
        </div>
        <div className="scroll-bottom-arrow">
          <div className="arrow-animated" />
          <div className="arrow-hint" />
        </div>
        <div id="content" className="timeline">
          <div className="container">
            {" "}
            <a name="60er" />
            <div id="year-1961" className="row">
              <div className="text-center timeline-item col-sm-8 col-sm-offset-1">
                <div className="timeline-year">1961</div>
                <img
                  className="product"
                  alt="bebe 1961"
                  src="/fileadmin/images/markenhistorie/markenhistorie.content.1961.produkt.png"
                  width="182"
                  height="182"
                />
                <p>
                  Alfred Riese, Geschäftsführer der Penaten AG, erfindet
                  speziell für ältere Babys und Kleinkinder eine Creme, die
                  erstmals komplett ohne Konservierungsstoffe auskommt. Seine
                  Innovation wollte Riese nach der französischen Filmdiva
                  Brigitte Bardot B. B. nennen – doch das damalige Markenrecht
                  ließ das nicht zu. Rieses kreative Lösung: kleine statt große
                  Buchstaben. Aus B. B. wurde{" "}
                  <strong>
                    bebe<sup>®</sup>
                  </strong>
                  .
                </p>
              </div>
            </div>
            <a name="70er" />
            <div id="year-1976" className="row">
              <div className="text-center timeline-item col-sm-5 col-sm-offset-6">
                <div className="timeline-year">1976</div>
                <img
                  className="brand"
                  src="/fileadmin/images/markenhistorie/markenhistorie.content.1976.brand.png"
                  width="87"
                  height="40"
                  alt
                />{" "}
                <img
                  className="products"
                  src="/fileadmin/images/markenhistorie/markenhistorie.content.1976.produkte.png"
                  width="553"
                  height="321"
                  alt
                />
                <p>
                  Das neue Design der{" "}
                  <strong>
                    bebe<sup>®</sup>
                  </strong>
                  &nbsp;im Look der 70er-Jahre.
                </p>
              </div>
            </div>
            <a name="80er" />
            <div id="year-1981" className="row">
              <div className="timeline-item text-center col-sm-5">
                <div className="timeline-year">1981</div>
                <p>
                  Die Produktpalette von{" "}
                  <strong>
                    bebe<sup>®</sup>
                  </strong>{" "}
                  wird erstmals erweitert: Zur Zartcreme kommt unter anderem ein
                  Shampoo hinzu.
                </p>
              </div>
            </div>
            <div id="year-1986" className="row">
              <div className="pull-right text-center timeline-item col-sm-6">
                <div className="timeline-year">1986</div>
                <img
                  className="brand"
                  src="/fileadmin/images/markenhistorie/markenhistorie.content.1986.brand.png"
                  width="233"
                  height="58"
                  alt
                />
                <p>
                  Johnson &amp; Johnson übernimmt die Penaten GmbH und damit die
                  Marken Penaten und{" "}
                  <strong>
                    bebe<sup>®</sup>
                  </strong>
                  .
                </p>
              </div>
            </div>
            <a name="90er" />
            <div id="year-1990" className="row">
              <div className="text-center timeline-item col-md-3 col-sm-4 col-sm-offset-4">
                <div className="timeline-year">1990</div>
                <img
                  className="brand"
                  src="/fileadmin/images/markenhistorie/markenhistorie.content.1990.brand.png"
                  width="132"
                  height="56"
                  alt
                />{" "}
                <img
                  className="products"
                  src="/fileadmin/images/markenhistorie/markenhistorie.content.1990.produkte.png"
                  width="468"
                  height="273"
                  alt
                />
                <p>
                  Aus{" "}
                  <strong>
                    bebe<sup>®</sup>
                  </strong>{" "}
                  wird die{" "}
                  <strong>
                    bebe Zartpflege<sup>®</sup>
                  </strong>
                  .
                </p>
              </div>
            </div>
            <div id="year-1992" className="row">
              <div className="text-center timeline-item col-md-3 col-md-offset-5 col-sm-4 col-sm-offset-4">
                <div className="timeline-year">1992</div>
                <img
                  className="product1"
                  src="/fileadmin/images/markenhistorie/markenhistorie.content.1992.produkt1.png"
                  width="242"
                  height="259"
                  alt
                />{" "}
                <img
                  className="product2"
                  src="/fileadmin/images/markenhistorie/markenhistorie.content.1992.produkt2.png"
                  width="220"
                  height="300"
                  alt
                />
                <p>
                  Das Design der{" "}
                  <strong>
                    bebe<sup>®</sup>
                  </strong>{" "}
                  Zartpflege-Produkte verändert sich.
                </p>
              </div>
            </div>
            <div id="year-1993" className="row">
              <div className="text-center timeline-item col-sm-6 col-sm-offset-2">
                <div className="timeline-year">1993</div>
                <img
                  className="product"
                  src="/fileadmin/images/markenhistorie/markenhistorie.content.1993.produkte.hires.png"
                  width="500"
                  height="300"
                  alt
                />
                <p>
                  Die erste limitierte Edition der Zartpflege erscheint. Seitdem
                  zieren immer wieder süße Motive wie die Tigerente, Micky Mouse
                  oder Hello Kitty die Dosen.
                </p>
                <div className="teaser">
                  {" "}
                  <a href="/limited-editions/index.html">
                    {" "}
                    <img src="/fileadmin/images/markenhistorie/markenhistorie.content.limited_editions.teaser.png" />{" "}
                  </a>{" "}
                </div>
              </div>
            </div>
            <a name="2000er" />
            <div id="year-2002" className="row">
              <div className="text-center timeline-item col-sm-3 col-sm-offset-6">
                <div className="timeline-year">2002</div>
                <img
                  className="product1"
                  src="/fileadmin/images/markenhistorie/markenhistorie.content.2002.brand_produkt.png"
                  width="270"
                  height="229"
                  alt
                />{" "}
                <img
                  className="product2"
                  src="/fileadmin/images/markenhistorie/markenhistorie.content.2002.produkte.png"
                  width="422"
                  height="319"
                  alt
                />
                <p>
                  Das neue sanftes shower &amp; shampoo erweitert die{" "}
                  <strong>
                    bebe<sup>®</sup>
                  </strong>{" "}
                  Zartpflege-Serie.{" "}
                </p>
              </div>
            </div>
            <div id="year-2011" className="row">
              <div className="text-center timeline-item col-sm-6 col-sm-offset-2">
                <div className="timeline-year">2011</div>
                <img
                  className="product1"
                  src="/fileadmin/images/markenhistorie/markenhistorie.stage.2011.produkt1.png"
                  width="160"
                  height="160"
                  alt
                />{" "}
                <img
                  className="product2"
                  src="/fileadmin/images/markenhistorie/markenhistorie.stage.2011.produkt2.png"
                  width="226"
                  height="226"
                  alt
                />
                <p>
                  {" "}
                  <strong>
                    bebe Zartpflege<sup>®</sup>
                  </strong>{" "}
                  feiert 50. Geburtstag mit einer limitierten Zartcreme Edition
                  und einem großen Malwettbewerb. Die Gewinner-Motive wurden im
                  Jahr darauf sogar auf der{" "}
                  <strong>
                    bebe<sup>®</sup>
                  </strong>{" "}
                  Zartcreme-Dose umgesetzt.{" "}
                </p>
              </div>
            </div>
            <div id="year-2014" className="row">
              <div className="text-center timeline-item col-sm-5 col-sm-offset-5">
                <div className="timeline-year">2014</div>
                <img
                  className="product1"
                  src="/fileadmin/images/markenhistorie/markenhistorie.content.2014.produkt1.png"
                  width="248"
                  height="114"
                  alt
                />{" "}
                <img
                  className="product2"
                  src="/fileadmin/images/markenhistorie/markenhistorie.content.2014.produkt2.png"
                  width="260"
                  height="136"
                  alt
                />
                <div className="indent">
                  <div className="indent">
                    <p>
                      {" "}
                      <strong>
                        bebe Zartpflege<sup>®</sup>
                      </strong>{" "}
                      bekommt Zuwachs: Die neuen{" "}
                      <strong>
                        bebe Zartpflege<sup>®</sup>
                      </strong>{" "}
                      Milde Reinigungstücher für Kids – der praktische Helfer
                      für zu Hause und unterwegs. Maja und ihre Freunde zieren
                      die Tücher-Neuheit und die beliebten Dosen
                      <strong> </strong>der<strong> </strong>
                      <strong>
                        <strong>
                          bebe<sup>®</sup>
                        </strong>{" "}
                      </strong>
                      Zartcreme.
                    </p>
                  </div>
                </div>
                <p className="small">
                  {" "}
                  Nach Waldemar Bonsels “Die Biene Maja und ihre Abenteuer” und
                  “Himmelsvolk”
                  <br /> © Studio 100 Media, Buzz Studios, Screen Australia and
                  Screen NSW.
                  <br />{" "}
                  <a
                    href="http://www.diebienemaja-derfilm.de/"
                    target="_blank"
                    className="external-link-new-window"
                  >
                    www.DieBieneMaja-DerFilm.de
                  </a>{" "}
                  -{" "}
                  <a
                    href="http://www.studio100.de/"
                    target="_blank"
                    className="external-link-new-window"
                  >
                    www.studio100.de
                  </a>{" "}
                  - ™ Studio 100
                  <br />{" "}
                </p>
              </div>
            </div>
            <div id="year-2015" className="row">
              <div className="timeline-item col-sm-8 col-sm-offset-1">
                <div className="timeline-year">2015</div>
                <img
                  className="product1"
                  src="/fileadmin/images/markenhistorie/year-2015.dusche.desktop.png"
                  width="167"
                  height="220"
                  alt
                />{" "}
                <img
                  className="product2"
                  src="/fileadmin/images/markenhistorie/year-2015.tuecher.desktop.png"
                  width="335"
                  height="160"
                  alt
                />
                <div className="indent text-center">
                  <p>
                    Ab Mai 2015 gibt es wieder etwas Neues bei{" "}
                    <strong>
                      bebe Zartpflege<sup>®</sup>
                    </strong>{" "}
                    zu entdecken! Ein neues, verspieltes Design auf allen
                    Produkten – mit unserem Freund, dem kleinen Spatz. Damit
                    macht die milde Pflege von{" "}
                    <strong>
                      bebe Zartpflege<sup>®</sup>
                    </strong>{" "}
                    noch mehr Spaß.
                  </p>
                </div>
              </div>
            </div>
            <div id="year-2019" className="row">
              <div className="timeline-item col-sm-8 col-sm-offset-3">
                <div className="timeline-year">2019</div>
                <img
                  className="product1"
                  src="/fileadmin/images/markenhistorie/year-2019.produkte.desktop.png"
                  width="460"
                  height="192"
                  alt
                />{" "}
                <img
                  className="product2"
                  src="/fileadmin/images/markenhistorie/year-2019.vivi.desktop.png"
                  width="167"
                  height="220"
                  alt
                />
                <p>
                  Willkommen in der neuen Welt von{" "}
                  <strong>bebe Zartpflege®</strong>! Ab März 2019 gibt es wieder
                  Neuigkeiten, denn wir haben nicht nur neue Produkte, sondern
                  auch neue Designs für euch – geziert von unseren
                  wunderhübschen Mädels. So möchten wir euch ein bisschen Freude
                  zurückgeben.
                </p>
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
        <script
          src="/fileadmin/js/vendor/jquery6b21.js?1400849731"
          type="text/javascript"
        />
        <script
          src="/fileadmin/js/vendor/detectmobilebrowser6b21.js?1400849731"
          type="text/javascript"
        />
        <script
          src="/fileadmin/js/vendor/bootstrap.min6b21.js?1400849731"
          type="text/javascript"
        />
        <script
          src="/fileadmin/js/vendor/jquery.ba-throttle-debounce.min6b21.js?1400849731"
          type="text/javascript"
        />
        <script
          src="/fileadmin/js/vendor/skrollr.min6b21.js?1400849731"
          type="text/javascript"
        />
        <script
          src="/fileadmin/js/vendor/isMobile.min6b21.js?1400849731"
          type="text/javascript"
        />
        <script
          src="/fileadmin/js/vendor/jquery.scrollTo.min6b21.js?1400849731"
          type="text/javascript"
        />
        <script
          src="/fileadmin/js/script71c5.js?1527068951"
          type="text/javascript"
        />
      </div>
    );
  }
}

export default Page;
